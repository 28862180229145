import {classes} from './HeaderLoader.st.css';
import React from 'react';

export const HeaderLoader = () => {
  return (
    <div className={classes.frame}>
      <div key={'header-main'} className={classes.main} />
    </div>
  );
};
